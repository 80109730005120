import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Callus from '../../../components/callus/callus'
import ButtonBlocks from "../../../components/buttonblocks/buttonblocks"
import ButtonBlock from "../../../components/buttonblock/buttonblock"

import "./covid.scss"
import BioHazard from "./bio-hazard.png"

const IndexPage = () => (
  <Layout>

    <SEO title="My Google Reviews Are Not Visible! - Five Star Review System" />

    <div>
      <img src={BioHazard} className="float-left mr-3 mb-3" alt="COVID-19 Pandemic Graphic"/>

      <h2>My Google Reviews Are Not Being Displayed</h2>

      <p>During the COVID-19 pandemic there have been some issues regarding Google reviews. Please indicate below if you are a reviewer or a business owner so we can get you the best information that is currently available.</p>

    </div>


    <ButtonBlocks>
      <ButtonBlock
        text="I'm a Business Owner"
        link='/lb/covid-19-reviews-hidden/business-owner'
        description="Reviews written by my customers are not being displayed."
      />
      <ButtonBlock
        text="I'm a Reviewer"
        link='//support.google.com/business/answer/9792336?hl=en'
        description="I wrote a review on Google but it isn't showing up."
      />
    </ButtonBlocks>

    <Callus />

  </Layout>
)

export default IndexPage
